import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import ShowDetailClassFreePurchaseModal from '../common/modals/show-detail-class-free-purchase-modal';
import { useGetClassFreePurchaseExelFile } from '../../../core/hooks/getReports/useGetClassFreePurchaseExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { useGetClassFreePurchase } from '../../../core/hooks/getReports/useGetClassFreePurchase';

const ClassFreePurchase = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
  const [selectedPurchase, setSelectedPurchase] = useState<any>();
  const { classFreePurchase } = useGetClassFreePurchase(dateRange);
  const { refetchClassFreePurchase } = useGetClassFreePurchaseExelFile(dateRange);

  const columns = [
    { field: "userId", header: "ID de Usuario" },
    { field: "userName", header: "Nombre de Usuario" },
    { field: "userEmail", header: "Correo Electrónico" },
    { field: "activationDateTime", header: "Fecha de Activación" },
    { field: "purchaseDateTime", header: "Fecha de Compra" },
    { field: "hasPurchaseAfterActivation", header: "Tiene Compra Después de Activación" },
  ];

  const getColumns = useResponsiveColumns(columns);

  const handleShowInfo = (rowData: any) => {
    console.log(rowData);
    setSelectedPurchase(null);
    setTimeout(() => setSelectedPurchase(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchClassFreePurchase();
      downloadExcelFile(file, 'class-free-purchase-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Compra de Clases Gratis</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => setDateRange(getDefaultMonthRange())}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={classFreePurchase || []}
            sortField='userName'
            lazy={false}
            sortOrder={-1}
            columns={getColumns()}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron compras."
          />
        </div>
        <ShowDetailClassFreePurchaseModal selectedPurchaseData={selectedPurchase} />
      </div>
    </div>
  );
};

export default ClassFreePurchase;
