import React from 'react';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';

export const customerSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Dashboard',
    icon: <i className="ti ti-layout-grid me-3" />,
  },
  {
    link: all_routes.customerCalendar,
    name: 'Agenda',
    icon: <Icon.Calendar className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerCart,
    name: 'Carrito',
    icon: <Icon.ShoppingCart className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.customerReservation,
    name: 'Reservar clase',
    icon: <Icon.Briefcase className="sidebar-feather me-3" />,
  },
  {
    link: "/#package-section",
    name: 'Tienda',
    icon: <Icon.ShoppingBag className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.purchaseHistory,
    name: 'Historial de compra',
    icon: <Icon.Archive className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.redeemGiftcard,
    name: 'Canjear GiftCard',
    icon: <Icon.Gift className="sidebar-feather me-3" />,
  },
];

export const trainerSidebarData = [
  {
    link: all_routes.customerDashboard,
    name: 'Editar Perfil',
    icon: <i className="ti ti-layout-grid me-2" />,
  },
  {
    link: all_routes.customerCalendar,
    name: 'Agenda',
    icon: <Icon.Calendar className="sidebar-feather me-3" />,
  },
  {
    link: all_routes.trainerSchedules,
    name: 'Clases',
    icon: <Icon.Activity className="sidebar-feather me-3" />,
  },
];
