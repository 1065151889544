import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { getCart } from '../../../../core/services/cartService';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Package } from '../../../../core/Interface/repositoryInterfaces';
import { PricingPlansProps } from '../../../../core/Interface/interfaceComponents';
import { getPricingPlansConfig } from "../../../../core/data/config/carouselConfig";

const PricingPlans: React.FC<PricingPlansProps> = ({ packages, cartContext, authContext }) => {
  const routes = all_routes;
  const { user } = authContext;
  const { cart, addToCart, setCart } = cartContext;
  const [loadingPlan, setLoadingPlan] = useState<number | null>();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, [setCart]);

  useEffect(() => {
    const heights = Array.from(document.querySelectorAll('.pricing-planscontent')).map(
      (el) => el.getBoundingClientRect().height
    );
    setMaxHeight(Math.max(...heights));
  }, [packages]);

  const handleAddToCart = (packageId: number) => {
    setLoadingPlan(packageId);
    setTimeout(() => {
      addToCart(packageId, setCart);
      setLoadingPlan(null);
    }, 1000);
  };

  const renderPackage = (pkg: Package, index: number) => (

    <div className="col-lg-4 col-sm-12" key={index}>
      <div className={`pricing-plans price-new ${isMobile ? 'shadow-none' : ''}`}>
        <div className="pricing-planshead">
          <h6>S/ {pkg.price}</h6>
          <h4>{pkg.title}</h4>
          <h5>{pkg.subtitle}</h5>
        </div>
        <div className="pricing-planscontent mb-2" style={{ minHeight: `${maxHeight}px` }}>
          <ul>
            {pkg.description.split('|').map((feature: string, i: number) => (
              <li key={i}>
                {feature.trim() && (
                  <>
                    <i className="fa fa-check-circle me-2" />
                    <span>{feature.trim()}</span>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="pricing-btn">
            {cart.some((item: Package) => item.id === pkg.id) ? (
              <Link to={routes.customerCart} className="btn btn-view">
                <i className="fa fa-check me-2 ms-0"></i>
                Ver Carrito
              </Link>
            ) : (
              <Link
                to="#"
                className={`btn btn-view ${loadingPlan === pkg.id ? 'disabled' : ''}`}
                onClick={(e) => {
                  if (loadingPlan === pkg.id) {
                    e.preventDefault();
                  } else {
                    handleAddToCart(pkg.id);
                  }
                }}
              >
                {loadingPlan === pkg.id ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  'Comprar'
                )}
              </Link>
            )}
          </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <Slider
          {...getPricingPlansConfig(packages.length >= 4 ? 4 : packages.length)}
          className="slick-slider slick-initialized slide-arrow-primary flush"
        >
          {packages.map(renderPackage)}
        </Slider>
      ) : (
        <div className="row aos">
          {packages.map(renderPackage)}
        </div>
      )}
    </>
  );
};

export default PricingPlans;