import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { getCart } from '../../../../core/services/cartService';
import { Package } from '../../../../core/Interface/repositoryInterfaces';
import { ProductCarouselProps } from '../../../../core/Interface/interfaceComponents';
import { getProductCarouselConfig } from '../../../../core/data/config/carouselConfig';

const ProductCarousel: React.FC<ProductCarouselProps> = ({ products, cartContext, authContext }) => {
  const { user } = authContext;
  const { cart, addToCart, setCart, removeFromCart } = cartContext;
  const slidesToShowCount = products.length >= 4 ? 4 : products.length;
  const carouselConfig = getProductCarouselConfig(slidesToShowCount);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, [user?.userId]);

  useEffect(() => {
      const heights = Array.from(document.querySelectorAll('.providerset-content-seven')).map(
        (el) => el.getBoundingClientRect().height
      );
      setMaxHeight(Math.max(...heights));

    // handleResize();
    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, [products]);

  const handleRemoveFromCart = (productId: number) => {
    removeFromCart(productId, setCart);
  };

  const handleAddToCart = (productId: number) => {
    addToCart(productId, setCart);
  };

  const renderPackage = (product: Package, index: number) => (
    <div className="col-md-4 text-home">
      <div className="providerset m-1 mx-md-2 d-flex flex-column justify-content-between" key={index}>
        <div>
          <div className="providerset-img providerset-img-seven d-flex justify-content-center product-image">
            {product.image && (
              <ImageWithBaseApi routeName="packet" src={product.image} alt={product.title} />
            )}
          </div>
          <div className="providerset-content providerset-content-seven" style={{ minHeight: `${maxHeight}px` }}>
            <div className="providerset-price mb-0">
              <div className="providerset-name">
                <h4 className="text-home" style={{marginBottom: '1px'}}>
                  {product.title}
                </h4>
                {product.description && (
                  <span style={{fontSize: '15px', whiteSpace: 'pre-line'}}>
                    {product.description.split('|').join(' - ')}
                  </span>
                )}
              </div>
            </div>

          </div>
        </div>
        <div className="mt-auto">
        <div className="provider-rating-seven mt-3">
              <div className="providerset-prices">
                <h6>
                  S/ {product.price}
                </h6>
              </div>
              {cart.some((item: Package) => item.id === product.id) ? (
            (() => {
              const productInCart = cart.find((item: Package) => item.id === product.id);
              return (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-light me-2"
                    onClick={() => handleRemoveFromCart(product.id)}
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-light me-2">
                    {productInCart?.quantity}
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => handleAddToCart(product.id)}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
              );
            })()
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => handleAddToCart(product.id)}
            >
              Agregar al Carrito
            </button>
          )}
            </div>

        </div>
      </div>
    </div>
  );

  return (
    <div className="row aos">
      <div className="col-md-12">
        <Slider {...carouselConfig} className="slick-slider slide-arrows slide-arrow-primary outside product-service">
          {products.map(renderPackage)}
        </Slider>
      </div>
    </div>
  );
};

export default ProductCarousel;