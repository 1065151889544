import React, { useContext } from 'react';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import AuthFooter from './common/footer';
import { InputField } from '../../../admin/common/input/inputField';
import { AuthContext } from '../../../../core/context/AuthContext';
import { useLogin } from '../../../../core/hooks/auth/useLogin';

const Login2 = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { login } = authContext;
  const { formData, handleChange, handleLogin, loading, serverError } =
  useLogin(login, "login-modal");

  const routes = all_routes;

  return (
    <>
      <PagesAuthHeader />
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 mx-auto">
              <form onSubmit={handleLogin}>
                <div className="d-flex flex-column justify-content-center">
                  <div className="card card-shadow p-sm-4 my-5">
                    <div className="card-body">
                      <div className="text-center mb-3">
                        <h3 className="mb-2">Bienvenido a Lift</h3>
                        <p>Ingrese sus credenciales para acceder a su cuenta</p>
                      </div>
                      <InputField
                        label="Correo Electronico"
                        name='email'
                        value={formData?.email || ""}
                        onChange={handleChange}
                        error={serverError?.find((err) => err.field === 'email')?.message}
                        />
                        <div className="mb-3">
                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <label className="form-label">Contraseña</label>
                            <Link
                              to={`${routes.index}?recover=true`}
                              className="link-primary fw-medium text-decoration-underline mb-1 fs-14"
                            >
                              ¿Olvidaste tu contraseña?
                            </Link>
                          </div>
                          <InputField
                              type="password"
                              name="password"
                              value={formData?.password || ""}
                              onChange={handleChange}
                              error={serverError?.find((err) => err.field === 'password')?.message}
                          />
                        </div>
                        <div className='mb-3'>
                          {serverError?.find(error => error.field === 'server') && (
                              <small className="text-danger mb-3">
                                {serverError?.find(error => error.field === 'server')?.message}
                              </small>
                          )}
                        </div>
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-linear-primary w-100"
                        >
                          {loading ? (
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Iniciando Sesión...
                            </>
                          ) : (
                            'Iniciar sesión'
                          )}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p>
                          ¿No tiene una cuenta?{" "}
                          <Link to={routes.userSignup} className="link-primary">
                            {" "}
                            Regístrese hoy
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div>
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-left-top"
                        alt="Img"
                      />
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-right-bottom"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </>
  );
};

export default Login2;
