import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCart } from '../../../../core/services/cartService';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Package } from '../../../../core/Interface/repositoryInterfaces';
import { PackageSliderProps } from '../../../../core/Interface/interfaceComponents';
import { getPackageSliderConfig } from '../../../../core/data/config/carouselConfig';

const PackageSlider: React.FC<PackageSliderProps> = ({ packages, cartContext, authContext }) => {
  const routes = all_routes;
  const { user } = authContext;
  const { cart, addToCart, setCart } = cartContext;
  const [loadingPlan, setLoadingPlan] = useState<number | null>();
  const slidesToShowCount = packages.length >= 4 ? 4 : packages.length;
  const carouselConfig = getPackageSliderConfig(slidesToShowCount);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, [setCart]);

  useEffect(() => {
    const heights = Array.from(document.querySelectorAll('.pricing-planscontent')).map(
      (el) => el.getBoundingClientRect().height
    );
    setMaxHeight(Math.max(...heights));
  }, [packages]);

  const handleAddToCart = (packageId: number) => {
    setLoadingPlan(packageId);
    setTimeout(() => {
      addToCart(packageId, setCart);
      setLoadingPlan(null);
    }, 1000);
  };

  const renderPackage = (pkg: Package, index: number) => (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12 a w-10" style={{ width: '20% !important' }} key={index}>
      <div className="hidden-charge-main pricing-plans m-1 m-md-2 price-new">
        <h4>{pkg.title}</h4>
        <div className="pricing-planscontent features-slide" style={{ maxWidth: '100%', minHeight: `${maxHeight}px` }}>
          <ul className="p-0 list-package">
            {pkg.description.split('|').map((feature: string, i: number) => (
              <li key={i} className="text-start">
                {feature.trim() && (
                  <>
                  <i className="fa fa-check-circle me-2" />
                  <span className="text-start">{feature.trim()}</span>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden-charges-bottom-mark text-center">
          <h1>S/ {pkg.price}</h1>
          <h6>{pkg.subtitle}</h6>
        </div>
        {cart.some((item: Package) => item.id === pkg.id) ? (
          <Link to={routes.customerCart} className="w-100 btn btn-view">
              <i className="fa fa-check me-2 ms-0"></i>
              Ver Carrito
          </Link>
        ) : (
          <Link
            to="#"
            className={`btn w-100 btn-view ${loadingPlan === pkg.id ? 'disabled' : ''}`}
            onClick={(e) => {
              if (loadingPlan === pkg.id) {
                e.preventDefault();
              } else {
                handleAddToCart(pkg.id);
              }
            }}
          >
          {loadingPlan === pkg.id ? (
            <i className="fa fa-spinner fa-spin ms-0"></i>
            ) : (
            'Comprar'
          )}
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <>
        <Slider {...carouselConfig} className="slick-slider slide-arrows slide-arrow-primary flush">
          {packages.map(renderPackage)}
        </Slider>
    </>
  );
};

export default PackageSlider;