import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomersRoutes from './customers.routes';
import NewFooter from '../home/footer/main-footer';
import HomeHeader from '../home/header/main-header';
import { all_routes } from '../../../core/data/routes/all_routes';
import { AuthContext } from '../../../core/context/AuthContext';
import AuthModals from '../home/header/authModals';
import { useLocation } from 'react-router-dom';

const Customers = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const authContext = useContext(AuthContext);
  const location = useLocation();

  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { isAuthenticated, user } = authContext;

  useEffect(() => {
    if (!isAuthenticated && currentPath !== '/perfil/cart') {
      navigate(routes.login);
    }

    if (
      (currentPath === '/perfil/schedules' || currentPath === '/perfil/attendance-management') &&
      !(user?.role === 'trainer' || user?.role === 'admin')
    ) {
      navigate('/perfil');
    }

    if (currentPath === '/perfil/reservation' && user?.role === 'trainer') {
      navigate('/perfil');
    }

    // currentPath.includes("/perfil")
    //   ? import("../../../style/css/components.css")
    //   : import("../../../style/scss/main.scss");
  }, [currentPath, navigate, isAuthenticated, user, routes.login]);

  return (
    <>
      <HomeHeader type={1}/>
      <CustomersRoutes />
      <NewFooter />
      <AuthModals />
    </>
  );
};

export default Customers;