import React, { useState, useEffect } from 'react';
import { ServiceInformationProps as Props } from '../../../core/Interface/interfaceComponents';

const ServiceInformation: React.FC<Props> = ({ nextTab, onUsersSelection, usersSuscription }) => {
  const [users, setUsers] = useState<any[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [warning, setWarning] = useState<string | null>(null);

  useEffect(() => {
    setUsers(usersSuscription);
  }, [usersSuscription]);

  useEffect(() => {
    const selectedUsers = users
      .filter((user) => user.selected)
      .map(({ email, fullName }) => ({ email, fullName })); // Solo guardar email y fullName
    sessionStorage.setItem('selectedUsers', JSON.stringify(selectedUsers));
    setSelectedCount(selectedUsers.length);
    onUsersSelection(selectedUsers); // Pasar solo los campos necesarios
  }, [users]);

  const toggleSelection = (id: number) => {
    if (selectedCount >= 100 && !users.find((user) => user.id === id)?.selected) {
      setWarning('No puedes seleccionar más de 100 usuarios.');
      return;
    }
    setUsers((prev) =>
      prev.map((user) =>
        user.id === id ? { ...user, selected: !user.selected } : user
      )
    );
  };

  const toggleAllSelection = (selectAll: boolean) => {
    if (selectAll && users.filter((user) => user.selected).length + users.filter((user) => !user.selected).length > 100) {
      setWarning('No puedes seleccionar más de 100 usuarios.');
      return;
    }
    setUsers((prev) =>
      prev.map((user) => ({ ...user, selected: selectAll }))
    );
  };

  const handleNextTab = () => {
    if (selectedCount === 0) {
      setWarning('Debes seleccionar al menos un usuario antes de continuar.');
    } else {
      setWarning(null);
      nextTab();
    }
  };

  return (
    <div className="service-information">
      <fieldset id="first-field">
        <div className="container-service space-service pb-2">
          <div className="sub-title mb-4">
            <h4 className="text-secondary">Seleccionar Usuarios</h4>
            <p>Elige los usuarios a quienes deseas enviar correos electrónicos.</p>
          </div>

          <div className="card card-shadow  review-item">
            <div className="card-body pb-0">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 gap-3">
                <div className="d-flex flex-wrap gap-2">
                  <button
                    className="btn btn-primary-admin d-flex align-items-center"
                    onClick={() => toggleAllSelection(true)}
                  >
                    <i className="fa fa-check-square me-2"></i>
                    Seleccionar Todo
                  </button>
                  <button
                    className="btn btn-secondary d-flex align-items-center"
                    onClick={() => toggleAllSelection(false)}
                  >
                    <i className="fa fa-minus-square me-2"></i>
                    Desmarcar Todo
                  </button>
                </div>
                <span
                  className="badge bg-primary-admin d-flex align-items-center justify-content-center"
                  style={{
                    padding: '10px 20px',
                    fontSize: '1rem',
                    borderRadius: '8px',
                    fontWeight: 'normal',
                    minWidth: '180px',
                  }}
                >
                  <i className="fa fa-envelope me-2"></i>
                  Seleccionados: {selectedCount}
                </span>
              </div>

              <div className="row">
                {users.map((user) => (
                  <div key={user.id} className="col-md-4 mb-3">
                    <div
                      className={`card card-shadow shadow-sm ${user.selected ? 'border-success-admin bg-light' : ''}`}
                      onClick={() => toggleSelection(user.id)}
                      style={{
                        cursor: 'pointer',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.03)')}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div>
                          <h6 className="mb-1 text-dark">{user.fullName}</h6>
                          <p className="text-muted mb-0">{user.email}</p>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={user.selected}
                            onChange={() => toggleSelection(user.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            {warning && (
              <div className="alert alert-warning mb-3" role="alert">
                {warning}
              </div>
            )}
            <div className="text-end">
              <button
                className="btn btn-primary-admin next_btn mb-4"
                type="button"
                onClick={handleNextTab}
              >
                Siguiente <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default ServiceInformation;