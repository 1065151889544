import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { apiRepository } from '../../../api/apiRepository';
import { Banners } from '../../../core/Interface/repositoryInterfaces';
import {
  addNewCategoryRow as addNewCategoryRowCore,
  deleteServiceRow as deleteServiceRowCore,
  handleOnDragEnd as handleOnDragEndCore,
} from '../../../core/services/dragAndDropServices';
import {
  validateCategory,
  ValidationError,
} from '../../../core/form/validationForm';
import { InputField, DropdownField } from '../common/input/inputField';
import { TYPE_PACKAGE, TYPE_SUB_SERVICE } from '../../../core/data/options/options';

const CategoriesList = () => {
  const [services, setServices] = useState<Banners[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const addNewCategoryRow = () => {
    const updatedServices = addNewCategoryRowCore(services);
    setServices(updatedServices);
  };

  const deleteServiceRow = async (id: string) => {
    const updatedServices = await deleteServiceRowCore(
      id,
      services,
      apiRepository,
    );
    setServices(updatedServices);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const updatedServices = handleOnDragEndCore(result, services);
    setServices(updatedServices);
  };

  const handleInputChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, [name]: value } : service,
    );
    setServices(updatedServices);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const updatedServices = services.map(
        (service) =>
          service.id === id
            ? { ...service, image: event.target.files[0] }
            : service, // Guardar el archivo real
      );
      setServices(updatedServices);
    }
  };

  const handleCheckboxChange = (id: string) => {
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, status: !service.status } : service,
    );
    setServices(updatedServices);
  };

  const validateServices = (services: Service[]): ValidationError[] => {
    let allErrors: ValidationError[] = [];
    services.forEach((service) => {
      const errors = validateCategory(service).map((error) => ({
        ...error,
        id: service.id,
      }));
      allErrors = allErrors.concat(errors);
    });
    return allErrors;
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await apiRepository.getPackageTypes();
      const servicesWithStringIds = response.data.data.map((service) => ({
        ...service,
        id: service.id.toString(),
        image: service.image || '',
        status: service.status,
        title: service.title || '',
        subtitle: service.subtitle || '',
        upload: true,
      }));

      servicesWithStringIds.sort((a, b) => a.position - b.position);

      setLoading(false);
      setServices(servicesWithStringIds);
    } catch (error) {
      console.error('Error al obtener los datos de paquetes:', error);
    }
  };

  const updateCreateCategories = async () => {
    setErrors([]);
    const validationErrors = validateServices(services);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      for (const service of services) {
        if (!service.upload) {
          await apiRepository.createPackageTypes(service);
        } else {
          await apiRepository.updatePackageTypes(service.id, service);
        }
    }


      validationErrors.push({
        field: 'success',
        message: '¡La actualización de las categorias fue exitosa!',
      });
      setErrors(validationErrors);
      await fetchCategories();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const cleanedMessage = error.response.data.message.replace(
          /^Bad request: /,
          '',
        );
        validationErrors.push({ field: 'server', message: cleanedMessage });
      } else {
        validationErrors.push({
          field: 'server',
          message:
            'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.',
        });
      }
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="page-wrapper custom-home">
      <div className="content">
        <div className="container-service col-lg-11 col-sm-12 m-auto">
          <div className="container-service">
            <div className="row">
              <div className="col-sm-12">
                <div className="additional">
                  <div className="sub-title Service">
                    <h6>Tipos de Categorias</h6>
                  </div>
                </div>
              </div>
            </div>
            {errors.find((error) => error.field === 'success') && (
              <div className="alert alert-success" role="alert">
                {errors.find((error) => error.field === 'success')?.message}
              </div>
            )}
            {errors.find((error) => error.field === 'server') && (
              <div className="alert alert-danger" role="alert">
                {errors.find((error) => error.field === 'server')?.message}
              </div>
            )}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-services">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {services.map((service, index) => (
                      <Draggable
                        key={service.id}
                        draggableId={service.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="row service-cont"
                            style={{
                              backgroundColor: snapshot.isDragging
                                ? '#f9f9f9'
                                : 'white',
                              padding: '10px',
                              borderRadius: '10px',
                              marginBottom: '10px',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div className="col-md-2">
                              <div className="form-group truncate-label">
                                <InputField
                                  label="Nombre de la categoria"
                                  name="name"
                                  value={service.name || ''}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'name',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group truncate-label">
                                <InputField
                                  className="form-control"
                                  label="Titulo de la Sección"
                                  name="title"
                                  value={service.title || ''}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'title',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group truncate-label">
                                <InputField
                                  className="form-control"
                                  label="Subtitulo de la Sección"
                                  name="subtitle"
                                  value={service.subtitle || ''}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'subtitle',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group truncate-label">
                                <DropdownField
                                  label="Tipo de Categoria"
                                  name="type"
                                  appendTo={null}
                                  classNameInput="admin"
                                  value={service.type || ''}
                                  options={TYPE_PACKAGE}
                                  onChange={(event) =>
                                    handleInputChange(service.id, event)
                                  }
                                  error={
                                    errors.find(
                                      (error) =>
                                        error.id === service.id &&
                                        error.field === 'type',
                                    )?.message
                                  }
                                />
                              </div>
                            </div>
                            {(service.type === 1 ) && (
                              <div className="col-md-2">
                                <div className="form-group truncate-label">
                                  <DropdownField
                                    label="Subtipos de Categoria"
                                    name="subType"
                                    appendTo={null}
                                    value={service.subType || ''}
                                    options={TYPE_SUB_SERVICE}
                                    onChange={(event) => handleInputChange(service.id, event)}
                                    error={
                                      errors.find(
                                        (error) =>
                                          error.id === service.id && error.field === "type"
                                      )?.message
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-md-1">
                              <div className="form-group">
                                <label>Estado</label>
                                <br />
                                <div className="active-switch">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="status"
                                      checked={service.status}
                                      onChange={() =>
                                        handleCheckboxChange(service.id)
                                      }
                                    />
                                    <span className="sliders round" />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/*{services.length > 1 && (*/}
                            {/*  <div className="col-md-1">*/}
                            {/*    <button*/}
                            {/*      onClick={() => deleteServiceRow(service.id)}*/}
                            {/*      className="btn btn-danger-outline delete-icon"*/}
                            {/*    >*/}
                            {/*      <Icon.Trash2 className="react-feather-custom trashicon" />*/}
                            {/*    </button>*/}
                            {/*  </div>*/}
                            {/*)}*/}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="row">
              <div className="col-6 d-flex justify-content-start">
                <Link
                  to="#"
                  className="link-secondary add-extra"
                  onClick={addNewCategoryRow}
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Agregar Categoria
                </Link>
              </div>

              <div className="col-6 d-flex justify-content-end">
                <button
                  className="btn btn-primary-admin"
                  type="button"
                  onClick={updateCreateCategories}
                >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> 
                    Guardando...
                  </>
                ) : (
                  'Guardar cambios'
                )}                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
