import React, { useState, useEffect } from 'react';
import { InputField } from '../common/input/inputField';
import Quill from 'quill';
import ReactQuill from 'react-quill';

type Props = {
  prevTab: CallableFunction;
  nextTab: CallableFunction;
  onEmailData: (emailData: any) => void;
  emailData: any[];
};

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static sanitize(url) {
    if (url.startsWith('/')) {
      return url;
    } else if (url.match(/^https?:\/\//)) {
      return url;
    }
    return `http://${url}`;
  }
}

Quill.register(CustomLink, true);

const EmailEditor: React.FC<Props> = ({ prevTab, nextTab, onEmailData, emailData }) => {
  const [formData, setFormData] = useState({
    subject: emailData.subject || '',
    description: emailData.description || '',
    content: emailData.content || '<h2>Bienvenido al editor</h2><p>Este es un ejemplo de <strong>contenido</strong> predefinido. Puedes editarlo como desees.</p>',
  });

  const [errors, setErrors] = useState<{ subject?: string; description?: string }>({});

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Eliminar el error si el campo ya está completo
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
    }
  };

  useEffect(() => {
    onEmailData(formData);
  }, [formData]);

  const handleNext = () => {
    const newErrors: { subject?: string; description?: string } = {};

    if (!formData.subject.trim()) {
      newErrors.subject = 'El asunto es obligatorio.';
    }

    if (!formData.description.trim()) {
      newErrors.description = 'La descripción es obligatoria.';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      nextTab();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],
    ],
  };

  return (
    <>
      <div className="card-section">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-service">
              <h4 className="text-secondary mb-2">Editor de Email</h4>
            </div>
          </div>

          <div className="col-md-12">
            <InputField
              label="Nombre del Correo"
              value={formData.subject}
              onChange={(e) => handleInputChange('subject', e.target.value)}
              placeholder="Ingresa el asunto del correo"
              error={errors.subject}
            />
          </div>

          <div className="col-md-12">
            <InputField
              label="Asunto del Correo"
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              placeholder="Ingresa una breve descripción"
              error={errors.description}
            />
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>Contenido</label>
              <ReactQuill
                theme="snow"
                value={formData.content}
                className="editor-email"
                onChange={(value) => handleInputChange('content', value)}
                modules={modules}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="bottom-btn">
            <div className="field-btns">
              <button
                className="btn btn-prev prev_btn"
                type="button"
                onClick={prevTab}
              >
                <i className="fas fa-arrow-left" /> Anterior
              </button>
              <button
                className="btn btn-primary-admin next_btn"
                type="button"
                onClick={handleNext}
              >
                Siguiente <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailEditor;
