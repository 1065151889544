import React, { useState } from 'react';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SubTypesModal from '../common/modals/sub-types.modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsClasses';
import { useSubTypeClasses } from '../../../core/hooks/getData/useSubTypeClasses';

const ClassesWithSubTypes = () => {
    const [selectedSubType, setSelectedSubType] = useState<any>();
    const { subTypeClassesData, fetchSubTypeClasses } = useSubTypeClasses({ shouldTransform: false, shouldOptions: false });

    const handleEditClick = (rowData: any) => {
        setSelectedSubType(null);
        setTimeout(() => setSelectedSubType(rowData), 0);
    };

    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'description', header: 'Descripción' },
        { field: 'className', header: 'Clase' },
    ];

    const getColumns = useResponsiveColumns(columns);

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Tipo de Clases</h5>
                    <div className="list-btn">
                        <button
                            className="btn btn-primary-admin"
                            type="button"
                            onClick={handleEditClick}
                        >
                            <i className="fa fa-plus me-2" />
                            Agregar Tipo de Clase
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={subTypeClassesData || []}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    showGridlines
                    emptyMessage="No se encontraron tipos de clases."
                />
            </div>
            <SubTypesModal
                selectedSubType={selectedSubType}
                onUpdateSuccess={fetchSubTypeClasses}
            />
        </div>
    );
};

export default ClassesWithSubTypes;