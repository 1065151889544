import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../../../admin/common/input/inputField";
import { LoginModalProps } from "../../../../core/Interface/modalInterface";
import { useLogin } from "../../../../core/hooks/auth/useLogin";
import { getErrorForField } from "../../../../core/utils/utils";

const LoginModal: React.FC<LoginModalProps> = ({
  modalId = "login-modal",
  title = "Bienvenido a Lift",
  subtitle = "Ingresa tus datos para acceder a tu cuenta",
  authLoginContext,
}) => {
  const { formData, handleChange, resetForm, handleLogin, loading, serverError } =
    useLogin(authLoginContext, modalId);

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleModalClose = () => {
      resetForm();
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleModalClose);
      }
    };
  }, [modalId, resetForm]);

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} data-bs-backdrop="static" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
            <Link to="#" data-bs-dismiss="modal" aria-label="Close">
              <i className="ti ti-circle-x-filled fs-20" />
            </Link>
          </div>
          <div className="modal-body p-4 pt-0">
            <form onSubmit={handleLogin}>
              <div className="text-center mb-3">
                <h3 className="mb-2">{title}</h3>
                <p>{subtitle}</p>
              </div>
              <InputField
                label="Correo Electrónico"
                required={false}
                placeholder="Ingresa tu correo electrónico"
                name="email"
                value={formData?.email || ""}
                onChange={handleChange}
                error={getErrorForField("email", serverError || [])}
              />
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <label className="form-label">Contraseña</label>
                  <Link
                    to="#"
                    className="link-primary fw-medium text-decoration-underline mb-1 fs-14"
                    data-bs-toggle="modal"
                    data-bs-target="#forgot-modal"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
                <InputField
                  type="password"
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  value={formData?.password || ""}
                  onChange={handleChange}
                  error={getErrorForField("password", serverError || [])}
                />
              </div>
              {serverError?.find((error) => error.field === "server") && (
                <div className="mb-3">
                  <small className="text-danger">
                    {serverError.find((error) => error.field === "server")?.message}
                  </small>
                </div>
              )}
              <div className="mb-3">
                <button type="submit" className="btn btn-lg btn-linear-primary w-100">
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Iniciando Sesión...
                    </>
                  ) : (
                    "Iniciar sesión"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <p>
                  ¿No tienes una cuenta?{" "}
                  <Link
                    to="#"
                    className="link-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#register-modal"
                  >
                    Únete hoy mismo
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;