import React from 'react';
import { LoadingButtonProps } from '../../core/Interface/interfaceComponents';

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading = false,
  type = "submit",
  customText = 'Guardar Cambios',
  loadingText = 'Guardando...',
  className = 'btn-primary',
  onClick,
}) => {
  return (
    <button
      type={type}
      className={`btn ${className}`}
      disabled={loading}
      onClick={onClick}
    >
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          {loadingText}
        </>
      ) : (
        customText
      )}
    </button>
  );
};

export default LoadingButton;