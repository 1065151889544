import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { all_routes } from '../../../../core/data/routes/all_routes';

interface NavbarLogoProps {
  imageUrl: string;
}

const NavbarLogo: React.FC<NavbarLogoProps> = ({ imageUrl }) => {
  const routes = all_routes;
  return (
    <>
      <Link to={routes.index} className="navbar-brand logo">
        <ImageWithBaseApi
          loadingIconFontSize="20px"
          src={imageUrl}
          routeName="enterprise"
          className="img-fluid logo"
          alt="Logo"
        />
      </Link>
      <Link to={routes.index} className="navbar-brand logo-small">
        <ImageWithBaseApi
          loadingIconFontSize="20px"
          src={imageUrl}
          routeName="enterprise"
          className="img-fluid logo"
          alt="Logo"
        />
      </Link>
    </>
  );
};

export default NavbarLogo;