import React from 'react';
import { Link } from 'react-router-dom';
import { SidebarLinksProps } from '../../../../../core/Interface/sidebarCustomerInterface';

const SidebarLinks: React.FC<SidebarLinksProps> = ({ sidebarData, activeRouterPath }) => (
  <div className="card-body user-sidebar-body p-0">
    <ul className="ps-0">
      {sidebarData.map((item, index) => (
        <li className="mb-4" key={index}>
          <Link
            to={item.link}
            className={`d-flex align-items-center ${activeRouterPath(item.link) && 'active'}`}
          >
            {item.icon}
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default SidebarLinks;
