import React, { useState } from 'react';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import { apiRepository } from '../../../../api/apiRepository';
import {
  InputField,
  CalendarField,
} from '../../../admin/common/input/inputField';
import { handleChange } from '../../../../core/utils/utils';
import { validateComplaint } from '../../../../core/form/validationForm';

const ComplaintForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    documentType: '',
    documentNumber: '',
    email: '',
    phoneNumber: '',
    department: '',
    province: '',
    district: '',
    address: '',
    reference: '',
    complaintType: '',
    consumptionType: '',
    receiptNumber: '',
    complaintDate: '',
    provider: '',
    productCost: '',
    productDescription: '',
    purchaseDate: '',
    consumptionDate: '',
    complaintDetails: '',
    customerRequest: '',
    isMinor: false,
    evidence: null,
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChange(e, setFormData);
  };


  const handleCheckChange = (event) => {
    const { type, checked, name } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : event.target.value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData({ ...formData, evidence: file });
  };

  const createComplaint = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateComplaint(formData);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    const formattedFormData = {
      ...formData,
      complaintDate: formatDate(formData.complaintDate),
      purchaseDate: formatDate(formData.purchaseDate),
      consumptionDate: formatDate(formData.consumptionDate),
    };

    const formDataSend = new FormData();
    for (const key in formattedFormData) {
      if (Object.prototype.hasOwnProperty.call(formattedFormData, key)) {
        formDataSend.append(key, formattedFormData[key]);
      }
    }

    try {
      await apiRepository.createComplaint(formDataSend);
      setErrors([]);
      validationErrors.push({ field: "success", message: '¡El reclamo fue exitoso!' });
      setErrors(validationErrors);

    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        validationErrors.push({ field: "server", message: cleanedMessage });
      } else {
        validationErrors.push({ field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.' });
      }
      setErrors(validationErrors);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <BreadCrumb title="Registrar Queja" item1="Home" item2="Registrar Queja" />
      <div className="page-wrapper my-5">
        <div className="content">
          <div className="container">
            <div className="row">
              <h4 className="mb-3">Información de la Queja</h4>
              <form onSubmit={createComplaint}>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <InputField
                      label="Nombre"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'firstName')?.message}
                      placeholder="Ingrese su nombre"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Apellido"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'lastName')?.message}
                      placeholder="Ingrese su apellido"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Tipo de Documento"
                      name="documentType"
                      value={formData.documentType}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'documentType')?.message}
                      placeholder="Seleccione un tipo de documento. Ejmp: DNI"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Número de Documento"
                      name="documentNumber"
                      value={formData.documentNumber}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'documentNumber')?.message}
                      placeholder="Ingrese el número de documento"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Correo"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'email')?.message}
                      placeholder="Ingrese su correo electrónico"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Teléfono"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'phoneNumber')?.message}
                      placeholder="Ingrese su número de teléfono"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Departamento"
                      name="department"
                      value={formData.department}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'department')?.message}
                      placeholder="Ingrese su departamento"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Provincia"
                      name="province"
                      value={formData.province}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'province')?.message}
                      placeholder="Ingrese su provincia"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Distrito"
                      name="district"
                      value={formData.district}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'district')?.message}
                      placeholder="Ingrese su distrito"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Dirección"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'address')?.message}
                      placeholder="Ingrese su dirección"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Referencia"
                      name="reference"
                      value={formData.reference}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'reference')?.message}
                      placeholder="Ingrese una referencia"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Tipo de Queja"
                      name="complaintType"
                      value={formData.complaintType}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'complaintType')?.message}
                      placeholder="Seleccione el tipo de queja"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Tipo de Consumo"
                      name="consumptionType"
                      value={formData.consumptionType}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'consumptionType')?.message}
                      placeholder="Ingrese el tipo de consumo"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Número de Recibo"
                      name="receiptNumber"
                      value={formData.receiptNumber}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'receiptNumber')?.message}
                      placeholder="Ingrese el número de recibo"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Nombre del Proveedor"
                      name="provider"
                      value={formData.provider}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'provider')?.message}
                      placeholder="Ingrese el nombre del proveedor"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Costo del Producto"
                      name="productCost"
                      value={formData.productCost}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'productCost')?.message}
                      placeholder="Ingrese el costo del producto"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Descripción del producto"
                      name="productDescription"
                      value={formData.productDescription}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'productDescription')?.message}
                      placeholder="Describa el producto"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Detalles del Reclamo"
                      name="complaintDetails"
                      value={formData.complaintDetails}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'complaintDetails')?.message}
                      placeholder="Ingrese los detalles del reclamo"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Solicitud del Reclamo"
                      name="customerRequest"
                      value={formData.customerRequest}
                      onChange={handleInputChange}
                      error={errors.find((err) => err.field === 'customerRequest')?.message}
                      placeholder="Ingrese su solicitud"
                    />
                  </div>
                  <div className="col-md-6">
                    <CalendarField
                      label="Fecha de Queja"
                      name="complaintDate"
                      value={formData.complaintDate}
                      onChange={(e) => setFormData({ ...formData, complaintDate: e.value })}
                      error={errors.find((err) => err.field === 'complaintDate')?.message}
                      placeholder="Seleccione la fecha de la queja"
                    />
                  </div>
                  <div className="col-md-6">
                    <CalendarField
                      label="Fecha de Consumo"
                      name="consumptionDate"
                      value={formData.consumptionDate}
                      onChange={(e) => setFormData({ ...formData, consumptionDate: e.value })}
                      error={errors.find((err) => err.field === 'consumptionDate')?.message}
                      placeholder="Seleccione la fecha de consumo"
                    />
                  </div>
                  <div className="col-md-6">
                    <CalendarField
                      label="Fecha de la Compra"
                      name="purchaseDate"
                      value={formData.purchaseDate}
                      onChange={(e) => setFormData({ ...formData, purchaseDate: e.value })}
                      error={errors.find((err) => err.field === 'purchaseDate')?.message}
                      placeholder="Seleccione la fecha de compra"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="isMinor"
                          checked={formData.isMinor}
                          onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="remember_me">
                          Soy{" "}
                          <span className="text-primary text-decoration-underline">
                            menor de edad.
                          </span>{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Evidencia</label> <br />
                    <div
                      className="dropzone"
                      onClick={() => document.getElementById('fileInput').click()}
                      onDrop={(e) => {
                        e.preventDefault();
                        const file = e.dataTransfer.files[0];
                        handleFileChange({ target: { files: [file] } });
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      style={{
                        border: "2px dashed #e64783",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <p>Arrastre y suelte su archivo aquí, o haga clic para seleccionar un archivo</p>
                      <input
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      {formData.evidence && (
                        <>
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={URL.createObjectURL(formData.evidence)}
                              alt="Evidence Preview"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {errors.find((error) => error.field === 'server') && (
                  <div className="alert alert-danger" role="alert">
                    {errors.find((error) => error.field === 'server')?.message}
                  </div>
                )}
                {errors.find((error) => error.field === 'success') && (
                  <div className="alert alert-success" role="alert">
                    {errors.find((error) => error.field === 'success')?.message}
                  </div>
                )}
                <div className="d-flex justify-content-end align-items-center">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? "Enviando..." : "Crear Reclamo"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintForm;