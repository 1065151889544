import React, { useState, useEffect } from 'react';
import { img_api } from '../../environment';

interface Image {
  className?: string;
  src: any;
  alt?: string;
  routeName?: string;
  height?: number;
  width?: number;
  id?: string;
  style?: any;
  isExternalLink?: boolean;
  loadingIconFontSize?: string;
  ladingClass?: string;
}

const defaultLoadingIcon = (fontSize: string) => (
  <i className="fa fa-spinner fa-spin d-flex justify-content-center align-items-center h-100 w-100" style={{ fontSize, color: '#5c5c5c' }}></i>
);

const SVGComponent = ({ url, className, style, onLoad }: { url: string; className?: string; style?: React.CSSProperties; onLoad: () => void }) => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((data) => {
        setSvgContent(data);
        onLoad();
      });
  }, [url, onLoad]);

  return <div className={className} style={style} dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

const ImageWithBaseApi = (props: Image) => {
  const [loading, setLoading] = useState(true);
  const fullSrc = `${img_api}${props.routeName}/${props.src}`;
  const loadingIcon = defaultLoadingIcon(props.loadingIconFontSize || '60px');

  const handleLoad = () => {
    setLoading(false);
  };

  const isSvg = props.src && props.src.split('.').pop().toLowerCase() === 'svg';

  const renderImage = isSvg ? (
    <SVGComponent
      url={fullSrc}
      className={props.className}
      style={{ ...props.style, display: loading ? 'none' : undefined }}
      onLoad={handleLoad}
    />
  ) : (
    <img
      className={props.className}
      src={fullSrc}
      height={props.height}
      alt={props.alt}
      width={props.width}
      id={props.id}
      style={{ ...props.style, display: loading ? 'none' : undefined }} // Oculta la imagen mientras carga
      onLoad={handleLoad}
    />
  );

  if (props.isExternalLink) {
    return (
      <a href={fullSrc} target="_blank" rel="noopener noreferrer">
        {loading && loadingIcon}
        {renderImage}
      </a>
    );
  }

  return (
    <>
      {loading && (
        <div className={props.ladingClass} style={{ position: 'relative', width: '100%', height: '100%' }}>
          {loadingIcon}
        </div>
      )}
      {renderImage}
    </>
  );
};

export default ImageWithBaseApi;
