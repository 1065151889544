import React, { useState, useEffect } from 'react';
import { convertStringToLocalDate, getCurrentLocalDate } from "../../../../../core/utils/dateUtils";
import { SidebarClassesProps } from '../../../../../core/Interface/sidebarCustomerInterface';
import { CSSTransition } from 'react-transition-group';
import * as Icon from 'react-feather';
import './SidebarClasses.css';

const SidebarClasses: React.FC<SidebarClassesProps> = ({ filteredClasses }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>();

  useEffect(() => {
    setIsCollapsed(true);
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (filteredClasses.length === 1) {
    const item = filteredClasses[0];
    return (
      <div className="card-body p-0 mt-1 mb-1">
        <h5 className="mb-3">Clase Disponible</h5>
        <div className="class-card mb-3 p-3 border rounded shadow-sm">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="badge bg-primary">{item.type}</span>
          </div>
          <div className="d-flex justify-content-between">
            <p className="m-0">
              {item.type === 'Membresias Premium' ? (
                <>Cantidad: <i className="fas fa-crown text-warning" /></>
              ) : item.type === 'Membresias' ? (
                <>Cantidad: <i className="fas fa-infinity text-primary" /></>
              ) : (
                <>Cantidad: {item.quantity}</>
              )}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-0">
            <span className="">Vence:</span>
            <span className={`badge ${item.endDate ? (convertStringToLocalDate(item.endDate) < getCurrentLocalDate() ? 'bg-danger' : 'bg-success') : 'bg-secondary'} text-white`}>
              {item.endDate ? convertStringToLocalDate(item.endDate).toLocaleDateString() : 'N/A'}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card-body p-0 mt-1 mb-1">
      {filteredClasses.length > 0 && (
        <>
          <div className="d-flex align-items-center mb-3 position-relative" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
            <h5 className="m-0">Clases Disponibles</h5>
            {isCollapsed ? <Icon.ChevronDown size={20} className="ms-3" /> : <Icon.ChevronUp className="ms-3" size={20} />}
          </div>
          <CSSTransition
            in={!isCollapsed}
            timeout={300}
            classNames="collapse"
            unmountOnExit
          >
            <div>
              {filteredClasses.map((item) => (
                <div key={item.id} className="class-card mb-3 p-3 border rounded shadow-sm">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="badge bg-primary">{item.type}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0">
                      {item.type === 'Membresias Premium' ? (
                        <>Cantidad: <i className="fas fa-crown text-warning" /></>
                      ) : item.type === 'Membresias' ? (
                        <>Cantidad: <i className="fas fa-infinity text-primary" /></>
                      ) : (
                        <>Cantidad: {item.quantity}</>
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-0">
                    <span className="">Vence:</span>
                    <span className={`badge ${item.endDate ? (convertStringToLocalDate(item.endDate) < getCurrentLocalDate() ? 'bg-danger' : 'bg-success') : 'bg-secondary'} text-white`}>
                      {item.endDate ? convertStringToLocalDate(item.endDate).toLocaleDateString() : 'N/A'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CSSTransition>
        </>
      )}
    </div>
  );
};

export default SidebarClasses;