export const sliderOptions = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        { breakpoint: 1000, settings: { slidesToShow: 1 } },
        { breakpoint: 700, settings: { slidesToShow: 1, swipe: true, arrows: false } },
        { breakpoint: 550, settings: { slidesToShow: 1, swipe: true, arrows: false } },
        { breakpoint: 0, settings: { slidesToShow: 1, swipe: true, arrows: false } },
    ],
};