import { Banners } from '../../../../core/Interface/repositoryInterfaces';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import React from 'react';

export const Banner = ({ banner }: { banner: Banners }) => {
    const isMobile = window.innerWidth <= 768;
    const imageSrc = isMobile && banner.imageMobile ? banner.imageMobile : banner.image;

    return (
        <div className="slide-images">
            <div className="hero-content position-relative overflow-hidden">
                <div className="hero-image slide-images">
                    <ImageWithBaseApi
                        loadingIcon={<i className="fa fa-spinner fa-spin d-flex justify-content-center align-items-center h-100 w-100" style={{ fontSize: '84px', color: '#5c5c5c' }}></i>}
                        ladingClass="hero-loading"
                        routeName="banner"
                        src={imageSrc}
                        alt={banner.title || 'Banner Lift'}
                        className="shape-03"
                    />
                </div>
                <div className="container hero-action d-flex">
                    <div className="action-content col-md-6 col-xxl-8 col-xl-10 col-lg-11 text-left justify-content-center">
                        <h1 className="mb-2">{banner.title}</h1>
                        <p>{banner.subtitle}</p>
                        <div className="mb-2 appointment-btn">
                            <Link to={banner.path || '#'} className="btn btn-primary d-inline-flex align-items-center">
                                Ver más
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};