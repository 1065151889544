import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import AOS from 'aos';
import { apiRepository } from '../../../../api/apiRepository';

const PrivacyCookies = () => {
  const [cookiesContent, setCookiesContent] = useState('');

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const fetchPolicyData = async () => {
      try {
        const id = '3'; // Usa el ID correspondiente para "Política de Privacidad"
        const response = await apiRepository.getPage(id);
        if (response && response.data && response.data.data) {
          setCookiesContent(response.data.data.description);
        }
      } catch (error) {
        console.error('Error al cargar la política de cookies:', error);
      }
    };

    fetchPolicyData();
  }, []);

  return (
    <>
      {/* Breadcrumb */}
      <BreadCrumb title="Política de Cookies" item1="Home" item2="Política de Cookies" />
      {/* /Breadcrumb */}
      <div className="page-wrapper">
        <div className="content my-5">
          <div className="container">
            <div className="row">
              {/* Cookies Policy */}
              <div className="col-md-12">
                <div className="terms-content privacy-cont">
                  {cookiesContent ? (
                    <div dangerouslySetInnerHTML={{ __html: cookiesContent }} />
                  ) : (
                    <p>No hay políticas de cookies que mostrar.</p>
                  )}
                </div>
              </div>
              {/* /Cookies Policy */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyCookies;