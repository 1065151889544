import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { Provider } from 'react-redux';
import store from './core/data/redux/store';
import "../src/style/icon/tabler-icons/webfont/tabler-icons.css";
import "../src/style/icon/feather/css/iconfont.css";
import 'aos/dist/aos.css';
// import "../src/style/admin/css/admin.css";
import "../src/style/css/components.css";
import "../src/style/scss/main.scss";
import './index.css';
import { base_path } from './environment';
import AllRoutes from './feature-module/router/router';
import { CartProvider } from './core/context/CartContext';
import { AuthProvider, AuthContext } from './core/context/AuthContext';
import { ApiProvider } from "./core/context/ApiProvider";

const App: React.FC = () => {
  const { user } = React.useContext(AuthContext);

  return (
    <Provider store={store}>
        <ApiProvider>
          <CartProvider userId={user?.userId}>
            <AllRoutes />
          </CartProvider>
        </ApiProvider>
    </Provider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter basename={base_path}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
} else {
  console.error("Element with id 'root' not found.");
}