import React from 'react';
import UserAvatar from '../../../common/userAvatar/userAvatar';
import { SidebarHeaderProps } from '../../../../../core/Interface/sidebarCustomerInterface';

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ userImage, user, handleUpdatePhoto }) => (
  <div className="card-header user-sidebar-header mb-3">
    <div className="d-flex justify-content-center align-items-center flex-column">
      <UserAvatar image={userImage || ''} onUpdate={handleUpdatePhoto} />
      <h6 className="mb-2">{user?.username || 'Inicia tu Sesión'}</h6>
    </div>
  </div>
);

export default SidebarHeader;
