import React from 'react';
import { SectionHeaderProps } from '../../../../core/Interface/interfaceComponents';

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => (
  <div className="row">
    <div className="col-md-12 text-center aos">
      <div className="section-heading sec-header text-home">
        <h2 className="text-center text-home">{title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  </div>
);

export default SectionHeader;