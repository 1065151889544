import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { AuthContext } from '../../../../core/context/AuthContext';
import { usePromotionSection } from '../../../../core/hooks/getData/usePromotionSection';

const TrialSection = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const userProfile = user?.role;
  const { promotionSectionData, isLoading } = usePromotionSection();

  return (
    <>
      <section className="service-section">
        {userProfile !== 'customer' && userProfile !== 'trainer' && (
          <div className="container py-md-5 col-11 py-3" style={{ height: isLoading ? '800px' : 'auto' }}>
            <div className="row py-md-4 mt-0 align-items-center">
              {/* <div className="col-md-6 col-12">
                <div className="appimg aos d-flex justify-content-center" style={{ maxWidth: 'auto', height: '100%', maxHeight: '600px' }}>
                  {promotionSectionData?.image && (
                    <ImageWithBaseApi
                    src={promotionSectionData.image}
                    style={{ maxWidth: 'auto', height: '100%', maxHeight: '600px' }}
                    className="img-fluid"
                    alt="Promoción"
                    routeName="homesection"
                    loadingIconFontSize="80px"
                    ladingClass="height-loading-trial"
                    />
                  )}
                </div>
              </div> */}
              <div className="col-12 d-flex justify-content-center">
                <div className="download-sec trial-section mt-3 mt-lg-0 mt-md-0 text-home text-center">
                  <div dangerouslySetInnerHTML={{ __html: promotionSectionData?.description || '' }} />
                  {!isLoading && (
                  <div className="downlaod-btn aos mt-3 d-flex justify-content-center align-items-center">
                    <div className="pricing-plans price-new no-min-height">
                      <Link to={promotionSectionData?.link || '/'} className="btn">
                        Reserva aquí
                      </Link>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TrialSection;