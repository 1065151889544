import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { AuthContext } from '../../../../core/context/AuthContext';
import UpdatePhotoModal from '../../common/modals/confirm-update-photo-modal';
import { customerSidebarData, trainerSidebarData } from '../common/sidebarData';
import { useUserData } from '../../../../core/hooks/getData/useUserData';
import { usePaymentDays } from '../../../../core/hooks/getData/usePaymentDays';
import { useFilteredClasses, useHandleUpdatePhoto } from '../../../../core/hooks/utils/sidebarHooks';
import SidebarHeader from './elements/SidebarHeader';
import SidebarClasses from './elements/SidebarClasses';
import SidebarLinks from './elements/SidebarLinks';
import { ClassItem } from '../../../../core/Interface/sidebarCustomerInterface';

const CustomerSideBar = (updateDays: any) => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const location = useLocation();
  const { formData: userData, refetchUserData: fetchUser } = useUserData(user?.userId || 0);
  const { paymentDays: classesQuantity, refetch: fetchDays } = usePaymentDays(user?.userId || 0);
  const [userImage, setUserImage] = useState<File | string>();
  const [showModal, setShowModal] = useState<boolean>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null as File | null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userData?.image) {
      setUserImage(userData.image);
    }
  }, [userData]);

  useEffect(() => {
    if (updateDays.service === true || updateDays.service === false) {
      fetchDays();
    }
  }, [updateDays]);

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  const sidebarData = () => {
    switch (user?.role) {
      case 'admin':
        return [...customerSidebarData, ...trainerSidebarData];
      case 'trainer':
        return trainerSidebarData;
      default:
        return customerSidebarData;
    }
  };

  const { handleUpdatePhoto, handleConfirmUpdate } = useHandleUpdatePhoto({
    setSelectedFile,
    setShowModal,
    setLoading,
    fetchUser,
    userData: userData ? { ...userData, id: Number(userData.id).toString() } : { id: '', },
    selectedFile: selectedFile || null,
  });

  const filteredClasses: ClassItem[] = useFilteredClasses(classesQuantity) || [];

  return (
    <>
      <StickyBox>
        <div className="card card-shadow slider-min user-sidebar mb-4">
          <SidebarHeader userImage={typeof userImage === 'string' ? userImage : ''} user={user} handleUpdatePhoto={handleUpdatePhoto} />
          {user?.role == 'customer' && (
            <SidebarClasses filteredClasses={filteredClasses} />
          )}
          <SidebarLinks sidebarData={sidebarData()} activeRouterPath={activeRouterPath} />
        </div>
        <UpdatePhotoModal
          show={showModal ||false}
          onClose={() => setShowModal(false)}
          onConfirm={handleConfirmUpdate}
          loading={loading || false}
        />
      </StickyBox>
    </>
  );
};

export default CustomerSideBar;