import { Pages } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect, useCallback } from "react";
import { handleChange } from "../../utils/utils";

export const usePageLegal = (id: number) => {
  const [pageData, setPageData] = useState<Pages[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPage = useCallback(async (idPage = id) => {
    setIsLoading(true);
    try {
      const response = await apiRepository.getPage(idPage);
      const rawData = response.data.data;
      setPageData(rawData);
    } catch (error) {
      console.error("Error al obtener los datos de la sección de Legal:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    fetchPage(id);
  }, [id]);

  useEffect(() => {
    return () => {
      setPageData([]);
    };
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setPageData);
  };

  return {
    pageData,
    isLoading,
    setPageData,
    refetch: fetchPage,
    handleInputChange,
  };
};