import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Banners } from '../../../../core/Interface/repositoryInterfaces';
import { validateBanner, ValidationError } from '../../../../core/form/validationForm';
import { InputField, SwitchField, ImageUploadField } from '../input/inputField';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import BaseModal from './baseModal';

interface EditBannerModalProps {
  show: boolean;
  banner: Banners;
  onClose: () => void;
  onUpdateSuccess: () => void;
}

const EditBannerModal: React.FC<EditBannerModalProps> = ({ show, banner, onClose, onUpdateSuccess }) => {
  const [bannerData, setBannerData] = useState<Banners>(banner);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBannerData(banner);
  }, [banner]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setBannerData({
      ...bannerData,
      [name]: newValue,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setBannerData({ ...bannerData, image: file });
};

  const handleRemoveImage = () => {
    setBannerData({ ...bannerData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
        fileInput.value = "";
    }
  };

  const handleFileChangeMob = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setBannerData({ ...bannerData, imageMobile: file });
};

  const handleRemoveImageMob = () => {
    setBannerData({ ...bannerData, imageMobile: null });
    const fileInput = document.getElementById("imgInpMob") as HTMLInputElement;
    if (fileInput) {
        fileInput.value = "";
    }
  };


  const handleConfirmUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const errors = await handleFormSubmit(
      bannerData.id,
      bannerData,
      validateBanner,
      {
        create: apiRepository.createBanner,
        update: apiRepository.updateBanner,
      },
      onUpdateSuccess,
      (data) => ({
        ...data,
        file: data.image,
        mobileImage: data.imageMobile,
      }),
      'Ocurrió un error al intentar guardar el banner. Inténtalo de nuevo.',
      true
    );

    if (errors) {
      setErrors(errors);
    }

    setLoading(false);
  };

  return (
    <BaseModal
      show={show}
      title="Editar Banner"
      onClose={onClose}
      onConfirm={handleConfirmUpdate}
      loading={loading}
    >
      <form onSubmit={handleConfirmUpdate}>
        <InputField
          label="Titulo"
          name="title"
          value={bannerData.title || ''}
          onChange={handleInputChange}
          error={getErrorMessage(errors, 'title')}
        />
        <InputField
          label="Descripción"
          name="subtitle"
          value={bannerData.subtitle || ''}
          onChange={handleInputChange}
          error={getErrorMessage(errors, 'subtitle')}
        />
        <InputField
          label="Link"
          name="path"
          value={bannerData.path || ''}
          onChange={handleInputChange}
          error={getErrorMessage(errors, 'path')}
        />
        <ImageUploadField
          formData={bannerData || ''}
          onFileChange={handleFileChange}
          onRemoveImage={handleRemoveImage}
          routeName="banner"
          required={true}
          error={getErrorMessage(errors, 'image')}
          recommendedSize="1920 y 800 píxeles"
        />
        <ImageUploadField
          formData={bannerData || ''}
          onFileChange={handleFileChangeMob}
          onRemoveImage={handleRemoveImageMob}
          routeName="banner"
          imageKey="imageMobile"
          required={false}
          error={getErrorMessage(errors, 'imageMobile')}
          id = "imageMobile"
          label="Imagen Móvil"
          recommendedSize="500 y 500 píxeles"
        />
        <SwitchField
          label="Estado"
          checked={bannerData.status || false}
          onChange={handleInputChange}
          name="status"
        />
        {errors.find((error) => error.field === 'server') && (
          <div className="alert alert-danger" role="alert">
            {getErrorMessage(errors, 'server')}
          </div>
        )}
      </form>
    </BaseModal>
  );
};

export default EditBannerModal;
