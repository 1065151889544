import React, { useState, useEffect, useCallback } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Classes } from '../../../core/Interface/repositoryInterfaces';
import ClassesModal from '../common/modals/classes-modal';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { InputField, DropdownField } from '../common/input/inputField';
import ImageWithBaseApi from '../../../core/img/ImageWithBaseApi';
import { Link } from 'react-router-dom';
import {
    addNewServiceRow,
    handleOnDragEnd as handleOnDragEndCore,
} from '../../../core/services/dragAndDropServices';
import { validateClassesList, ValidationError } from '../../../core/form/validationForm';

const ClassesComponent = () => {
    const [data, setData] = useState<Classes[]>([]);
    const [headquarters, setHeadquarters] = useState<any[]>([]);
    const [selectedClass, setSelectedClass] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);

    const addNewClassRow = () => {
        setErrors([]);
        const updatedData = addNewServiceRow(data);
        setData(updatedData);
    };
    const handleOnDragEnd = (result: DropResult) => {
        const updatedData = handleOnDragEndCore(result, data);
        setData(updatedData);
    };

    const handleInputChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const updatedData = data.map((cls) =>
            cls.id === id ? { ...cls, [name]: value } : cls,
        );
        setData(updatedData);
    };

    const fetchClasses = useCallback(async () => {
        try {
            setLoading(true);
            const response = await apiRepository.getClasses();
            const classesWithStringIds = response.data.data.map((cls) => ({
                ...cls,
                id: cls.id.toString(),
                image: cls.image || '',
                status: cls.status,
                upload: true,
            }));

            classesWithStringIds.sort((a, b) => a.position - b.position);

            setData(classesWithStringIds);
        } catch (error) {
            console.error("Error al obtener los datos de clases:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchHeadquarters = async () => {
        try {
            const response = await apiRepository.getHeadquarters();
            const formattedHeadquarters = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setHeadquarters(formattedHeadquarters);
        } catch (error) {
            console.error("Error al obtener los datos de los establecimientos:", error);
        }
    };

    useEffect(() => {
        fetchClasses();
        fetchHeadquarters();
    }, [fetchClasses]);

    const handleEditClick = (rowData: any) => {
        setSelectedClass(null);
        setTimeout(() => setSelectedClass(rowData), 0);
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const updatedData = data.map((cls) =>
                cls.id === id ? { ...cls, file } : cls,
            );
            setData(updatedData);
        }
    };

    const handleCreateClasses = async () => {
        setErrors([]);
        const validationErrors = validateClassesList(data);

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);
        try {
            for (const cls of data) {
                const formData = new FormData();
                Object.entries(cls).forEach(([key, value]) => {
                    if (key === 'file' && value instanceof File) {
                        formData.append('file', value);
                    } else if (key !== 'image') {
                        formData.append(key, value as string | Blob);
                    }
                });
                formData.append('position', cls.position.toString());

                if (!cls.upload) {
                    await apiRepository.createClasses(formData);
                } else {
                    await apiRepository.updateClasses(cls.id, formData);
                }
            }

            validationErrors.push({
                field: 'success',
                message: '¡La actualización de las clases fue exitosa!',
            });
            setErrors(validationErrors);
            await fetchClasses();
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                const cleanedMessage = error.response.data.message.replace(
                    /^Bad request: /,
                    '',
                );
                validationErrors.push({ field: 'server', message: cleanedMessage });
            } else {
                validationErrors.push({
                    field: 'server',
                    message:
                        'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.',
                });
            }
            setErrors(validationErrors);
        } finally {
            setLoading(false);
        }
    };

    const getErrorMessage = (errors: ValidationError[], field: string, id: string) => {
        const error = errors.find((error) => error.field === field && error.id === id);
        return error ? error.message : '';
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Clases</h5>
                    <div className="list-btn">
                        <button
                            className="btn btn-primary-admin"
                            type="button"
                            onClick={addNewClassRow}
                        >
                            <i className="fa fa-plus me-2" />
                            Agregar Clase
                        </button>
                    </div>
                </div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable-classes">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {data.map((cls, index) => (
                                    <Draggable key={cls.id} draggableId={cls.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="row service-cont"
                                                style={{
                                                    backgroundColor: snapshot.isDragging ? '#f9f9f9' : 'white',
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                    marginBottom: '10px',
                                                    ...provided.draggableProps.style,
                                                }}
                                            >
                                                <div className="col-md-2">
                                                    <div className="form-group truncate-label">
                                                        <InputField
                                                            label="Titulo"
                                                            name="title"
                                                            value={cls.title || ''}
                                                            onChange={(event) => handleInputChange(cls.id, event)}
                                                            error={getErrorMessage(errors, 'title', cls.id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group truncate-label">
                                                        <InputField
                                                            className="form-control"
                                                            label="Descripción"
                                                            name="description"
                                                            value={cls.description || ''}
                                                            onChange={(event) => handleInputChange(cls.id, event)}
                                                            error={getErrorMessage(errors, 'description', cls.id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group truncate-label">
                                                        <InputField
                                                            className="form-control"
                                                            required={false}
                                                            label="Ruta de redirección"
                                                            name="path"
                                                            value={cls.path || ''}
                                                            onChange={(event) => handleInputChange(cls.id, event)}
                                                            error={getErrorMessage(errors, 'path', cls.id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group truncate-label">
                                                        <DropdownField
                                                            label="Tipo de clase"
                                                            name="isReformer"
                                                            appendTo={null}
                                                            value={cls.isReformer || ''}
                                                            options={[
                                                                { label: 'Clase Reformer', value: 'SI' },
                                                                { label: 'Clase Standard', value: 'NO' }
                                                            ]}
                                                            onChange={(event) => handleInputChange(cls.id, event)}
                                                            error={getErrorMessage(errors, 'isReformer', cls.id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group me-2">
                                                        <label className="form-label w-100">
                                                            <span>Imagen de la Clase<span className="text-danger"> *</span></span>
                                                        </label>
                                                        <div className="d-flex w-100 mb-3 align-items-start">
                                                            <div className="profile-upload-content">
                                                                <div className="profile-upload-btn">
                                                                    <div className="profile-upload-file btn btn-upload">
                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={(event) => handleFileChange(event, cls.id)}
                                                                        />
                                                                        <Link to="#">Subir</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="profile-upload-img">
                                                                {cls.file instanceof File ? (
                                                                    <img
                                                                        className="img-fluid shadow rounded"
                                                                        src={URL.createObjectURL(cls.file)}
                                                                        alt="Sube una imagen"
                                                                        style={{ width: 'auto', height: '50px' }}
                                                                    />
                                                                ) : (
                                                                    <ImageWithBaseApi
                                                                        loadingIconFontSize="20px"
                                                                        src={`${cls.image}`}
                                                                        routeName="class"
                                                                        className="img-fluid shadow rounded"
                                                                        alt="Sube una imagen"
                                                                        style={{ width: 'auto', height: '50px' }}
                                                                        isExternalLink={false}
                                                                        ladingClass="mx-4"
                                                                    />
                                                                )}
                                                                {getErrorMessage(errors, 'image', cls.id) && (
                                                                    <small className="text-danger">{getErrorMessage(errors, 'image', cls.id)}</small>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="form-group mb-0">
                                                        <button
                                                            className={`btn ${cls.status ? 'btn-primary-admin' : 'btn-outline-secondary'}`}
                                                            type="button"
                                                            onClick={() => handleEditClick(cls)}
                                                        >
                                                            Editar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {errors.find((error) => error.field === 'success') && (
                    <div className="alert alert-success" role="alert">
                        {errors.find((error) => error.field === 'success')?.message}
                    </div>
                )}
                {errors.find((error) => error.field === 'server') && (
                    <div className="alert alert-danger" role="alert">
                        {errors.find((error) => error.field === 'server')?.message}
                    </div>
                )}
                {errors.find((error) => error.field === 'warning') && (
                    <div className="alert alert-warning" role="alert">
                        {errors.find((error) => error.field === 'warning')?.message}
                    </div>
                )}
                <div className="row">
                    <div className="col-6 d-flex justify-content-start">
                        <Link
                            to="#"
                            className="link-secondary add-extra"
                            onClick={addNewClassRow}
                        >
                            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                            Agregar Clase
                        </Link>
                    </div>

                    <div className="col-6 d-flex justify-content-end">
                        <button
                            className="btn btn-primary-admin"
                            type="button"
                            onClick={handleCreateClasses}
                        >
                        {loading ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> 
                                Guardando...
                            </>
                        ) : (
                            'Guardar cambios'
                        )}
                        </button>
                    </div>
                </div>
            </div>
            <ClassesModal selectedClasses={selectedClass} onUpdateSuccess={fetchClasses} headquarters={headquarters} />
        </div>
    );
};

export default ClassesComponent;