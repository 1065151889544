import { Classes } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";
import { UseClassesOptions } from "../../Interface/hooksInterface";

export const useClasses = ({ shouldTransform = false, shouldOptions = false, filterByStatus = true }: UseClassesOptions) => {
    const [classesData, setClassesData] = useState(shouldOptions ? [] as { label: string; value: any }[] : [] as Classes[]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchClasses = async () => {
        try {
            const response = await apiRepository.getClasses();
            let rawData = response.data.data;

            if (shouldOptions) {
                const optionsData = rawData.map((item: any) => ({
                    label: item.title,
                    value: item.id,
                }));
                setClassesData(optionsData);
            } else {
                if (filterByStatus) {
                    rawData = rawData.filter((cls: any) => cls.status === true);
                }

                if (shouldTransform) {
                    const transformedData = rawData.map((item: any) => ({
                        id: item.id,
                        title: item.title,
                        headquartersId: item.headquartersId,
                        image: item.image,
                        isReformer: item.isReformer,
                        description: item.description,
                        headquartersName: item.headquartersName,
                        position: item.position,
                    }));
                    const sortedData = transformedData.filter((item: any) => item.position !== undefined)
                                                      .sort((a: any, b: any) => a.position - b.position)
                                                      .concat(transformedData.filter((item: any) => item.position === undefined));
                    setClassesData(sortedData);
                } else {
                    setClassesData(rawData);
                }
            }
        } catch (error) {
            console.error("Error al obtener los datos de las clases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClasses();
    }, [shouldTransform, shouldOptions, filterByStatus]);

    return { classesData, isLoading, fetchClasses: fetchClasses };
};