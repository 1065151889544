import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { handleChange } from '../../../../core/utils/utils';
import { InputField, DropdownField } from '../input/inputField';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import { SubTypesModalProps } from '../../../../core/Interface/modalInterface';
import { SubTypeClasses } from '../../../../core/Interface/repositoryInterfaces';
import { useClasses } from '../../../../core/hooks/getData/useClasses';
import { validateSubTypeClasses, ValidationError } from '../../../../core/form/validationForm';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

const SubTypesModal: React.FC<SubTypesModalProps> = ({ selectedSubType, onUpdateSuccess }) => {
    const [subTypeData, setSubTypeData] = useState<SubTypeClasses>();
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const { classesData } = useClasses({ shouldTransform: false, shouldOptions: true });

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
      ) => {
        handleChange(e, setSubTypeData);
      };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setSubTypeData(undefined);
        setLoading(false);
    };

    useEffect(() => {
        if (selectedSubType) {
            if (selectedSubType.id) {
                setSubTypeData(selectedSubType);
            }
            setShow(true);
        }
    }, [selectedSubType]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            subTypeData?.id || -1,
            subTypeData,
            validateSubTypeClasses,
            {
                create: apiRepository.createSubTypeClasses,
                update: apiRepository.updateSubTypeClasses,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            undefined,
            "Ocurrió un error al intentar guardar el subtipo. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            <div className="modal fade" id="add-subtypes">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Subtipo</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <BaseModal
                            show={show}
                            title={selectedSubType?.id ? "Editar Tipo de Clase" : "Agregar Tipo de Clase"}
                            onClose={handleClose}
                            onConfirm={handleConfirmCreate}
                            loading={loading}
                        >
                            <form onSubmit={handleConfirmCreate}>
                                <InputField
                                    label="Nombre"
                                    name="name"
                                    value={subTypeData?.name || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors || [], 'name')}
                                />
                                <InputField
                                    label="Descripción"
                                    name="description"
                                    value={subTypeData?.description || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors || [], 'description')}
                                />
                                <DropdownField
                                    label="Clase"
                                    options={classesData || []}
                                    name="classId"
                                    value={subTypeData?.classId || ''}
                                    onChange={handleInputChange}
                                    placeholder="Selecciona la clase"
                                    error={getErrorMessage(errors || [], 'classId')}
                                />
                                {errors?.find((error) => error.field === 'server') && (
                                    <div className="alert alert-danger" role="alert">
                                        {getErrorMessage(errors || [], 'server')}
                                    </div>
                                )}
                            </form>
                        </BaseModal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubTypesModal;