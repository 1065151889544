import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { CalendarRangeField, DropdownField } from '../common/input/inputField';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { useGetCustomerPreferencesReport } from '../../../core/hooks/getReports/useGetCustomerPreferencesReport';
import { useGetCustomerPreferencesReportExelFile } from '../../../core/hooks/getReports/useGetCustomerPreferencesReportExelFile ';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { useClasses } from '../../../core/hooks/getData/useClasses';
import { useTrainers } from '../../../core/hooks/getData/useTrainersUsers';
import ShowDetailCustomerPreferenceModal from '../common/modals/show-detail-customer-preference-modal';

const CustomerPreference = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
  const [classId, setClassId] = useState<string>('');
  const [trainerId, setTrainerId] = useState<string>('');
  const [minAge, setMinAge] = useState<string>('');
  const [maxAge, setMaxAge] = useState<string>('');
  const [selectedClass, setSelectedClass] = useState<any>();
  const { customerPreferences } = useGetCustomerPreferencesReport(dateRange, classId, trainerId, minAge, maxAge);
  const { refetchCustomerPreferencesReport } = useGetCustomerPreferencesReportExelFile(dateRange, classId, trainerId, minAge, maxAge);
  const { classesData } = useClasses({ shouldOptions: true });
  const { trainersData } = useTrainers({ shouldOptions: true });
  const columns = [
    { field: "classDate", header: "Fecha" },
    { field: "classHour", header: "Hora" },
    { field: "className", header: "Clase" },
    { field: "trainerName", header: "Entrenador" },
    { field: "userName", header: "Usuario" },
    { field: "age", header: "Edad" },
  ];

  const getColumns = useResponsiveColumns(columns);

  const handleShowInfo = (rowData: any) => {
    setSelectedClass(null);
    setTimeout(() => setSelectedClass(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    const isAgeRangeValid = (minAge && maxAge) || (!minAge && !maxAge);
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) && isAgeRangeValid) {
      const file = await refetchCustomerPreferencesReport();
      downloadExcelFile(file, 'customer-preferences');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Detalles de Clases</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange());
                    setClassId('');
                    setTrainerId('');
                    setMinAge('');
                    setMaxAge('');
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />{' '}
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <DropdownField
                    value={classId}
                    onChange={(e: any) => setClassId(e.value)}
                    options={classesData}
                    placeholder="Selecciona una clase"
                    className="mb-3"
                  />
                  <DropdownField
                    value={trainerId}
                    onChange={(e: any) => setTrainerId(e.value)}
                    options={trainersData}
                    placeholder="Selecciona un entrenador"
                    className="mb-3"
                  />
                  <div className="d-flex gap-3 mb-3">
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={minAge}
                      onChange={(e) => setMinAge(e.target.value)}
                      placeholder="Edad mínima"
                    />
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={maxAge}
                      onChange={(e) => setMaxAge(e.target.value)}
                      placeholder="Edad máxima"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={customerPreferences || []}
            sortField='classDate'
            lazy={false}
            sortOrder={-1}
            columns={getColumns()}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron detalles de clases."
          />
        </div>
        <ShowDetailCustomerPreferenceModal selectedClassData={selectedClass} />
      </div>
    </div>
  );
};

export default CustomerPreference;