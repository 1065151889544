import React, { useState, useEffect } from 'react'
import { apiRepository } from '../../../../api/apiRepository';
import { Classes } from '../../../../core/Interface/repositoryInterfaces';
import { validateClasses, ValidationError } from '../../../../core/form/validationForm';
import { InputField, DropdownField, SwitchField, ImageUploadField, MultiSelectField } from '../input/inputField';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService'
import * as Icon from 'react-feather';
import BaseModal from './baseModal';
import { IS_REFORMER } from '../../../../core/data/options/options';
import { UpdatePackageModalProps } from '../../../../core/Interface/modalInterface';

const ClassesModal: React.FC<UpdatePackageModalProps> = ({ selectedClasses, onUpdateSuccess, headquarters }) => {
    const [id, setId] = useState<number>(selectedClasses ? selectedClasses.id : -1);
    const [classesData, setClassesData] = useState<Classes>([]);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setClassesData({
            ...classesData,
            [name]: newValue
        });
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setClassesData([]);
        setLoading(false);
        setId(-1);
    };

    useEffect(() => {
        if (selectedClasses) {
            setClassesData({
                id: selectedClasses.id,
                path: selectedClasses.path,
                title: selectedClasses.title,
                headquartersId: selectedClasses.headquartersId,
                description: selectedClasses.description,
                image: selectedClasses.image,
                status: selectedClasses.status !== undefined && selectedClasses.status !== null ? selectedClasses.status : true,
                isReformer: selectedClasses.isReformer || "NO",
                file: selectedClasses.file || null,
                headquartersName: selectedClasses.headquartersName || '',
                position: selectedClasses.position || 0,
            });
            setId(selectedClasses.id);
            setShow(true);
        }
    }, [selectedClasses]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        setClassesData({ ...classesData, image: file });
    };

    const handleRemoveImage = () => {
        setClassesData({ ...classesData, image: null });
        const fileInput = document.getElementById("imgInp") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = true
        setLoading(true)

        const errors = await handleFormSubmit(
            id,
            classesData,
            validateClasses,
            {
                create: apiRepository.createClasses,
                update: apiRepository.updateClasses,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                file: data.image,
            }),
            "Ocurrió un error al intentar guardar la clase. Inténtalo de nuevo.",
            formData
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false)
    };

    return (
        <>
            <div className="modal fade" id="add-classes">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Clase</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <BaseModal
                            show={show}
                            title={id ? "Editar Clase" : "Agregar Clase"}
                            onClose={handleClose}
                            onConfirm={handleConfirmCreate}
                            loading={loading}
                        >
                            <form onSubmit={handleConfirmCreate}>
                                <InputField
                                    label="Titulo"
                                    name="title"
                                    value={classesData.title || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors, 'title')}
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                        <DropdownField
                                            label="Selecciona Establecimiento"
                                            options={headquarters}
                                            name="headquartersId"
                                            value={classesData.headquartersId}
                                            onChange={handleInputChange}
                                            placeholder="Selecciona el establecimiento"
                                            error={getErrorMessage(errors, 'headquartersId')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <DropdownField
                                            label="Es Reformer"
                                            options={IS_REFORMER}
                                            name="isReformer"
                                            value={classesData.isReformer}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'isReformer')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            label="Link"
                                            required={false}
                                            name="path"
                                            value={classesData.path || ''}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'path')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Descripción"
                                            name="description"
                                            value={classesData.description || ''}
                                            onChange={handleInputChange}
                                            error={getErrorMessage(errors, 'description')}
                                        />
                                    </div>
                                </div>
                                {/* <MultiSelectField
                                    label="Selecciona el Subtipo de Clase"
                                    name="subTypeClassId"
                                    value={classesData.subTypeClassId}
                                    onChange={(value) => setClassesData({ ...classesData, subTypeClassId: value })}
                                    options={options}
                                    placeholder="Selecciona el Subtipo de Clase"
                                    error={getErrorMessage(errors, 'subTypeClassId')}
                                /> */}
                                <ImageUploadField
                                    formData={classesData || ''}
                                    onFileChange={handleFileChange}
                                    onRemoveImage={handleRemoveImage}
                                    routeName="class"
                                    required={true}
                                    error={getErrorMessage(errors, 'image')}
                                />
                                <SwitchField
                                    label="Estado"
                                    checked={classesData.status || ''}
                                    onChange={handleInputChange}
                                    disabled={!classesData.id}
                                    name="status"
                                />
                                {errors.find(error => error.field === 'server') && (
                                    <div className="alert alert-danger" role="alert">
                                        {getErrorMessage(errors, "server")}
                                    </div>
                                )}
                            </form>
                        </BaseModal>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClassesModal;